import { Badge, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./additem.scss";
import printicon from "../../../assets/images/print-icon.svg";
import threedothorizontal from "../../../assets/images/three-dots-horizontal.svg";
import CustomModal from "../../../components/customModal/CustomModal";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useInventory from "./../../../Hooks/useInventory";
import { LookUpModel } from "./popUpModels/LookUpModel";
import { itemPagesettingName, toggleClass } from "../../../utils/commonUtils";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ItemCreate,
  selectedInventoryItem,
  Units,
} from "../../../redux/inventory/inventorySlice";
import { OrderingProductsModel } from "./popUpModels/orderingProductsModel";
import { UseUnitsModel } from "./popUpModels/useUnitsModel";
import { DropDownValues } from "../../../utils/types";
import useProducts from "../../../Hooks/useProduct";
import DatePicker from "react-date-picker";
import React from "react";
import useSettings from "../../../Hooks/useSettings";

type BarcodeValues = {
  useMfrBarcode: boolean;
  checkInternet: boolean;
  addNdcProductMaster: boolean;
  isMedicine: boolean;
  barcode: string;
};
const AddItem = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showUseUnitModal, setShowUseUnitModal] = useState(false);
  const [showCalcModal, setShowCalcModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [getuseUnitFieldVal, setGetuseUnitFieldVal] = useState("");
  const [useUnitTooltip, setUseUnitTooltip] = useState("");
  const selectedItem = useAppSelector(selectedInventoryItem);
  const {
    getItemDropdownVal,
    getNewBarcodeAPI,
    getNewBarcodeLoading,
    editInventoryItemAPI,
    getOrderingProductsAPI,
    getOrderingProductsLoading,
    editInventoryItemLoading,
    createItemDropdownvalLoading,
    barcodeDetailsLoading,
    getInventoryCountListAPI,
    createInventoryItemAPI,
    createInventoryItemLoading,
  } = useInventory();

  const [inventoryUnitOptions, setInventoryUnitOptions] = useState<
    DropDownValues[]
  >([]);
  const [rxTypes, setRxTypes] = useState<DropDownValues[]>([]);
  const { createProductAPI, createProductLoading } = useProducts();
  const [stocks, setStocks] = useState<DropDownValues[]>([]);
  const [orderingProdOptions, setOrderingProdOptions] = useState<
    DropDownValues[]
  >([]);
  const [usUnitsList, setUseUnitsList] = useState<any[]>([]);
  const [unitsList, setUnitsList] = useState<any[]>([]);
  const [unitIdReq, setUnitIdReq] = useState<string | null>(null);
  const [invUnitReqForUseUnit, setInvUnitReqForUseUnit] = useState<
    string | null
  >(null);
  const [inventoryCountList, setInventoryCountList] = useState<any[]>();
  const selectReqErr = "Select an option";
  const navigate = useNavigate();
  const location = useLocation();
  const {getSettingsAPI, getSettingsLoading} = useSettings();
  const [settingsList, setSettingsList] = useState<any>([]);
  const DynamicInitialValues: ItemCreate = {
    rxName: "",
    drugName: "",
    manufacturer: "",
    ndcCode: "",
    serialNumber: "",
    lotNo: "",
    cvx: "",
    expiryDate: "",
    rxSRXId: "",
    notes: "",
    unitId: "",
    rxTypeId: "",
    stockId: "",
    cptCode: "0",
    isRecalled: false,
    isActive: true,
    orderingProductMasterId: null,
    createdBy: currentUserId,
    isNeverExpire: false,
    isKit: false,
    isSerialTracked: false,
  };
  const DynamicValidation = {
    rxName: Yup.string().required("Item Name is Required"),
    rxSRXId: Yup.string().required("SRX Barcode is Required"),
    drugName: Yup.string().required("Item Group is Required"),
    manufacturer: Yup.string().required("Manufacturer Name is Required"),
    ndcCode: Yup.string().required("NDC is Required"),
    serialNumber: Yup.string(),
    lotNo: Yup.string().required("LOT Number is Required"),
    expiryDate: Yup.string().required("Expiration Date is Required"),
    cvx: Yup.string(),
    unitId: Yup.string().required(selectReqErr),
    rxTypeId: Yup.string().required(selectReqErr),
    stockId: Yup.string().required(selectReqErr),
    cptCode: Yup.string().required("Cost is Required"),
  };

  const [itemValidationSchema, setItemValidationSchema] =
    useState<any>(DynamicValidation);

  const [renderItemLookUpPage, setRenderItemLookUpPage] =
    useState<BarcodeValues>({
      useMfrBarcode: false,
      checkInternet: true,
      addNdcProductMaster: true,
      isMedicine: false,
      barcode: "",
    });
  const [formInitialValues, setFormInitialValues] =
    useState(DynamicInitialValues);

  const handleCostChange = (value: any) => {
    if (value.totalQuantity > 0 && value.price > 0) {
      return (value.price / value.totalQuantity).toFixed(4);
    }
  };
  const usUnits = (data: Units[]) => {
    setUseUnitsList(data);
  };
  const getSettings = () => {
    getSettingsAPI({ searchText: "" })
      .then((res: any) => {
        const settingsResult= res?.find(
                (option:any) => option.settingName.toLowerCase() === itemPagesettingName.toLowerCase());
        setSettingsList(settingsResult ? JSON.parse(settingsResult?.settingValue?.toString()): []);
      })
      .catch();
  };
  const getLabels = (unitsList: DropDownValues[], unit: Units[]) => {
    let val = "";
    const getUnitId = inventoryUnitOptions?.find(
      (option) => option.value === itemValues.unitId,
    )?.label;
    let tooltipVal = getUnitId ? `1 ${getUnitId} =` : "";
    unit.forEach((unit: Units) => {
      const unitVal = unitsList.find(
        (option: any) => option.value === unit.unitId,
      );
      if (unitVal?.label) {
        val = val ? val + ", " + unitVal?.label : unitVal?.label;
        tooltipVal =
          tooltipVal + "<br/>" + unit.conversionFactor + " " + unitVal?.label;
      }
    });
    setUseUnitTooltip(tooltipVal);
    setGetuseUnitFieldVal(val);
  };
  useEffect(() => {
    getLabels(unitsList, usUnitsList);
  }, [usUnitsList, unitsList]);

  useEffect(() => {
    if (location.pathname !== "/edit-inventory") {
      getNewBarcodeAPI().then((res: any) =>
        setFormInitialValues({ ...formInitialValues, rxSRXId: res }),
      );
    }
    getOrderingProductsAPI({ includeDeleted: false, active: true }).then(
      (res: any) => {
        if (res) {
          setOrderingProdOptions([
            { value: "", label: "select..." },
            ...res.map((option: any) => ({
              value: option.id,
              label: `${option.productNo} - ${option.productDescription} (${option.unit.unitCode})`,
            })),
          ]);
        }
      },
    );
    getItemDropdownVal()
      .then((res: any) => {
        if (res) {
          setInventoryUnitOptions(
            res.units?.map((option: any) => {
              return {
                value: option.id,
                label: option.unitName,
              };
            }),
          );
          setRxTypes(
            res.rxTypes?.map((option: any) => {
              return {
                value: option.id,
                label: option.rxTypeName,
              };
            }),
          );

          setStocks(
            res.stocks?.map((option: any) => {
              return {
                value: option.id,
                label: option.stockName,
              };
            }),
          );
          setUnitsList(
            res.units
              ?.map((option: any) => {
                return {
                  value: option.id,
                  label: option.unitName,
                };
              })
              .sort(
                (a: { sortOrder: number }, b: { sortOrder: number }) =>
                  a.sortOrder - b.sortOrder,
              ),
          );
          setRenderItemLookUpPage({
            ...res.itemPageSetting,
            barcode: formInitialValues.rxSRXId,
          });
        } else {
          setInventoryUnitOptions([]);
          setRxTypes([]);
          setStocks([]);
        }
      })
      .catch();
  }, []);

  useEffect(() => {
    if (location.pathname === "/edit-inventory") {
      setIsEdit(true);
      if (selectedItem?.id) {
        setFormInitialValues({
          rxName: selectedItem.rxName,
          drugName: selectedItem.drugName,
          manufacturer: selectedItem.manufacturer,
          ndcCode: selectedItem.ndcCode,
          serialNumber: selectedItem.serialNumber || "NA",
          lotNo: selectedItem.lotNo,
          cvx: selectedItem.cvx,
          expiryDate: selectedItem.expiryDate,
          rxSRXId: selectedItem.rxSRXId,
          notes: selectedItem.notes,
          unitId: selectedItem.unitId,
          rxTypeId: selectedItem.rxTypeId,
          stockId: selectedItem.stockId,
          cptCode: selectedItem.cptCode || "0",
          isRecalled: Boolean(selectedItem.isRecalled),
          isActive: Boolean(selectedItem.isActive),
          orderingProductMasterId: selectedItem.orderingProductMasterId ?? null,
          createdBy: currentUserId,
          isNeverExpire: selectedItem.isNeverExpire,
          isKit: Boolean(selectedItem.isKit),
          isSerialTracked: Boolean(selectedItem.isSerialTracked),
        });
        setUseUnitsList(selectedItem.useUnits);
      } else {
        navigate("/inventory");
      }
      getInventoryCountListAPI(selectedItem.id).then((res: any) => {
        if (res) {
          setInventoryCountList(
            res.filter((x: { quantity: string }) => parseInt(x.quantity) !== 0),
          );
        }
      });
    }
  }, []);
  useEffect(()=>{
    getSettings();
  },[]);

  const toggleCalcModal = () => {
    toggleClass();
    setShowCalcModal(!showCalcModal);
  };
  const toggleUseUnitModal = () => {
    toggleClass();
    setShowUseUnitModal(!showUseUnitModal);
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const setSelectedOPValue = (value: DropDownValues) => {
    setValues({
      ...itemValues,
      orderingProductMasterId: value.value,
    });
  };
  const checkUnitIdExists = () => {
    if (itemValues.unitId) {
      setUnitIdReq(null);
      toggleModal();
    } else {
      setUnitIdReq("Inventory Unit is required for this operation");
    }
  };
  const openUseUnitModel = () => {
    if (itemValues.unitId) {
      setInvUnitReqForUseUnit(null);
      toggleUseUnitModal();
    } else {
      setInvUnitReqForUseUnit("Inventory Unit is required for this operation");
    }
  };

  const redirectToListing = () => {
    navigate("/inventory");
  };

  const {
    handleChange: handleChangeCostCal,
    values: costCalValues,
    handleSubmit: costCalSubmit,
    touched: costCalTouched,
    errors: costCalErrors,
  } = useFormik({
    initialValues: {
      totalQuantity: 0,
      price: 0,
    },
    validationSchema: Yup.object({
      totalQuantity: Yup.number().required(),
      price: Yup.number().required(),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      setFormInitialValues({
        ...itemValues,
        cptCode: (values.price / values.totalQuantity).toFixed(4),
      });
      toggleCalcModal();
      setSubmitting(false);
    },
  });
  const setFormBarcodeValues = (data: any) => {
    const newData = {
      ...itemValues,
      rxName: data.rxName,
      drugName: data.drugName,
      manufacturer: data.manufacturer,
      ndcCode: data.ndcCode,
      lotNo: data.lotNo,
      cvx: data.cvx,
      expiryDate: data.expiryDate,
      serialNumber: data.serialNumber,
    };
    setValues(newData);
    if (data.barcode) {
      setValues({
        ...newData,
        rxSRXId: data.barcode,
      });
    }
  };
  const {
    handleChange: handleChangeItem,
    values: itemValues,
    handleSubmit: itemSubmit,
    touched: itemTouched,
    errors: itemErrors,
    setValues,
  } = useFormik({
    initialValues: formInitialValues,
    validationSchema: Yup.object(itemValidationSchema),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editInventoryItemAPI({
            ...values,
            id: selectedItem.id,
            useUnits: usUnitsList,
            orderingProductMasterId: values.orderingProductMasterId || null,
          });
        } else {
          await createInventoryItemAPI({
            ...values,
            useUnits: usUnitsList,
            orderingProductMasterId: values.orderingProductMasterId || null,
          }).then(async(res)=>{
            if(res?.success && renderItemLookUpPage.addNdcProductMaster){
             const isMed = rxTypes?.some(
                (option) => option.label.toLowerCase() === "medicine" && option.value === res.result?.rxTypeId,
              );
              await createProductAPI({
                productCode: res.result?.ndcCode,
                barcode: res.result?.rxSRXId,
                productName: res.result?.rxName,
                category: res.result?.drugName,
                manufacturer: res.result?.manufacturer,
                model: res.result?.cvx,
                isMedicine: isMed,
                isActive: res.result?.isActive,
                createdBy: res.result?.createdBy,
              }) 
            }
          });
        }

        redirectToListing();
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const expiryToggleAction = (event: any) => {
    if (event.target.checked) {
      setItemValidationSchema({
        ...itemValidationSchema,
        expiryDate: Yup.string().nullable(),
      });
      setValues({
        ...itemValues,
        expiryDate: null,
      });
    } else {
      setItemValidationSchema(DynamicValidation);
    }
  };
  const invUnitChange = (unit: string) => {
    handleChangeItem("unitId")(unit);
  };
  return (
    <div className="add-item-wrapper">
      {createInventoryItemLoading ||
      createItemDropdownvalLoading ||
      editInventoryItemLoading ||
      barcodeDetailsLoading ||
      getNewBarcodeLoading ||
      getOrderingProductsLoading ||
      getSettingsLoading ||
      createProductLoading ? (
        <Loading />
      ) : null}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Item</h2>
        <Button
          onClick={handleShow}
          className="lookupbtn"
          variant="link"
          disabled={isEdit}
        >
          <ReactSVG className="svg-box" wrapper="span" src={printicon} /> Lookup
        </Button>
      </div>
      <div className="add-item-box">
        <Form>
          <h5 className="form-section-title full-width">Basic Information</h5>
          <Form.Group className="mb-3 colspan2">
            <Form.Label>
             {settingsList?.ItemLabels && settingsList?.ItemLabels?.ItemName ? settingsList.ItemLabels?.ItemName :"Item Name"}<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Item Name"
              id="rxName"
              name="rxName"
              onChange={(e) => handleChangeItem("rxName")(e.target.value ?? "")}
              value={itemValues.rxName}
              isInvalid={itemTouched.rxName && !!itemErrors.rxName}
            />
            {itemTouched.rxName && itemErrors.rxName ? (
              <Form.Control.Feedback type="invalid">
                {itemErrors.rxName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
               {settingsList?.ItemLabels && settingsList?.ItemLabels?.SrxBarcode ? settingsList.ItemLabels?.SrxBarcode :"SRX Barcode"}
              </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Barcode"
              id="rxSRXId"
              name="rxSRXId"
              onChange={(e) =>
                handleChangeItem("rxSRXId")(e.target.value ?? "")
              }
              value={itemValues.rxSRXId}
              isInvalid={itemTouched.rxSRXId && !!itemErrors.rxSRXId}
              disabled={true}
            />
            {itemTouched.rxSRXId && itemErrors.rxSRXId ? (
              <Form.Control.Feedback type="invalid">
                {itemErrors.rxSRXId}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.ItemGroup ? settingsList.ItemLabels?.ItemGroup :"Item Group"}<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Item Group"
              id="drugName"
              name="drugName"
              onChange={(e) =>
                handleChangeItem("drugName")(e.target.value ?? "")
              }
              value={itemValues.drugName}
              isInvalid={itemTouched.drugName && !!itemErrors.drugName}
            />
            {itemTouched.drugName && itemErrors.drugName && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.drugName}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.Manufacturer ? settingsList.ItemLabels?.Manufacturer :"Manufacturer"}<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Manufacturer Name"
              id="manufacturer"
              name="manufacturer"
              onChange={(e) =>
                handleChangeItem("manufacturer")(e.target.value ?? "")
              }
              value={itemValues.manufacturer}
              isInvalid={itemTouched.manufacturer && !!itemErrors.manufacturer}
            />
            {itemTouched.manufacturer && itemErrors.manufacturer && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.manufacturer}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.OrderingProduct ? settingsList.ItemLabels?.OrderingProduct :"Ordering Product"}
              <Badge
                onClick={checkUnitIdExists}
                bg="primary"
                style={{ marginLeft: "5px" }}
              >
                Add New
              </Badge>
            </Form.Label>
            <Select
              classNamePrefix="react-select"
              options={orderingProdOptions}
              id="orderingProductMasterId"
              name="orderingProductMasterId"
              value={orderingProdOptions?.find(
                (option: DropDownValues) =>
                  option.value === itemValues.orderingProductMasterId,
              )}
              onChange={(option: any) =>
                handleChangeItem("orderingProductMasterId")(option.value)
              }
              className={`${
                itemTouched.orderingProductMasterId &&
                !!itemErrors.orderingProductMasterId
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
              placeholder="Select"
            />
            {itemTouched.orderingProductMasterId &&
              itemErrors.orderingProductMasterId && (
                <Form.Control.Feedback type="invalid">
                  {itemErrors.orderingProductMasterId}
                </Form.Control.Feedback>
              )}
            {unitIdReq && (
              <Form.Control.Feedback type="invalid">
                {unitIdReq}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.Ndc ? settingsList.ItemLabels?.Ndc :"NDC"}<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter NDC"
              id="ndcCode"
              name="ndcCode"
              onChange={(e) =>
                handleChangeItem("ndcCode")(e.target.value ?? "")
              }
              value={itemValues.ndcCode}
              isInvalid={itemTouched.ndcCode && !!itemErrors.ndcCode}
            />
            {itemTouched.ndcCode && itemErrors.ndcCode ? (
              <Form.Control.Feedback type="invalid">
                {itemErrors.ndcCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.Lot ? settingsList.ItemLabels?.Lot :"LOT"}<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter LOT Number"
              id="lotNo"
              name="lotNo"
              onChange={(e) => handleChangeItem("lotNo")(e.target.value ?? "")}
              value={itemValues.lotNo}
              isInvalid={itemTouched.lotNo && !!itemErrors.lotNo}
            />
            {itemTouched.lotNo && itemErrors.lotNo ? (
              <Form.Control.Feedback type="invalid">
                {itemErrors.lotNo}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.ExpirationDate ? settingsList.ItemLabels?.ExpirationDate :"Expiration Date"}<sup>*</sup>
            </Form.Label>
            <div className="input-with-button">
              <div className="calendar-box">
                <DatePicker
                  format="MM/dd/yyyy"
                  openCalendarOnFocus={false}
                  className={`${
                    itemTouched.expiryDate && !!itemErrors.expiryDate
                      ? "is-invalid-border"
                      : ""
                  } form-control`}
                  value={itemValues.expiryDate}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  showLeadingZeros={true}
                  disabled={itemValues.isNeverExpire}
                  onChange={(date) =>
                    handleChangeItem({
                      target: { name: "expiryDate", value: date },
                    })
                  }
                  clearIcon={null}
                  calendarIcon={<i className="bi bi-calendar"></i>}
                />
              </div>
              <Form.Check
                className="isneverexpire"
                label="Does not Expire"
                type="checkbox"
                id="isNeverExpire"
                name="isNeverExpire"
                checked={itemValues.isNeverExpire}
                onChange={(event) => {
                  expiryToggleAction(event);
                  handleChangeItem(event);
                }}
              />
            </div>
            {itemTouched.expiryDate && itemErrors.expiryDate && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.expiryDate}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.InventoryUnit ? settingsList.ItemLabels?.InventoryUnit :"Inventory Unit"}<sup>*</sup>
            </Form.Label>
            <Select
              placeholder="Select"
              classNamePrefix="react-select"
              options={inventoryUnitOptions}
              id="unitId"
              name="unitId"
              value={inventoryUnitOptions?.find(
                (option) => option.value === itemValues.unitId,
              )}
              onChange={(option: any) => {
                invUnitChange(option.value);
              }}
              className={`${
                itemTouched.unitId && !!itemErrors.unitId
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
              isDisabled={
                usUnitsList.length > 0 ||
                (inventoryCountList && inventoryCountList.length > 0)
              }
            />
            {itemTouched.unitId && itemErrors.unitId && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.unitId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.UseUnits ? settingsList.ItemLabels?.UseUnits :"Use Units"}
            </Form.Label>
            <div className="input-with-button">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  getuseUnitFieldVal ? (
                    <Tooltip className="custom-tooltip">
                      <p className="mb-0">
                        <span
                          dangerouslySetInnerHTML={{ __html: useUnitTooltip }}
                        />
                      </p>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
                trigger={["hover", "focus"]}
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Use Unit"
                  value={getuseUnitFieldVal}
                  isInvalid={!!invUnitReqForUseUnit}
                  disabled={true}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Click to view or edit</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <button
                  type="button"
                  className="use-unit-opt-btn"
                  onClick={openUseUnitModel}
                >
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={threedothorizontal}
                  />
                </button>
              </OverlayTrigger>
            </div>
            {invUnitReqForUseUnit && (
              <Form.Control.Feedback type="invalid">
                {invUnitReqForUseUnit}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.ItemType ? settingsList.ItemLabels?.ItemType :"Item Type"}<sup>*</sup>
            </Form.Label>
            <Select
              classNamePrefix="react-select"
              options={rxTypes}
              id="rxTypeId"
              name="rxTypeId"
              value={rxTypes?.find(
                (option) => option.value === itemValues.rxTypeId,
              )}
              onChange={(option: any) =>
                handleChangeItem("rxTypeId")(option.value)
              }
              className={`${
                itemTouched.rxTypeId && !!itemErrors.rxTypeId
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
              placeholder="Select Item Type"
            />
            {itemTouched.rxTypeId && itemErrors.rxTypeId && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.rxTypeId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.Stock ? settingsList.ItemLabels?.Stock :"Stock"}<sup>*</sup>
            </Form.Label>
            <Select
              classNamePrefix="react-select"
              options={stocks}
              id="stockId"
              name="stockId"
              value={stocks?.find(
                (option) => option.value === itemValues.stockId,
              )}
              onChange={(option: any) =>
                handleChangeItem("stockId")(option.value)
              }
              className={`${
                itemTouched.stockId && !!itemErrors.stockId
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
              placeholder="Select"
            />
            {itemTouched.stockId && itemErrors.stockId && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.stockId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label={settingsList?.ItemLabels && settingsList?.ItemLabels?.Active ? settingsList.ItemLabels?.Active :"Active"}
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChangeItem}
                checked={itemValues.isActive}
              />
              <Form.Check
                inline
                label={settingsList?.ItemLabels && settingsList?.ItemLabels?.SerialTracked ? settingsList.ItemLabels?.SerialTracked :"Serial Tracked"}
                type="checkbox"
                name="isSerialTracked"
                onChange={handleChangeItem}
                checked={itemValues.isSerialTracked}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.CvxModel ? settingsList.ItemLabels?.CvxModel :"CVX/Model"}
              </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter CVX/Model"
              id="cvx"
              name="cvx"
              onChange={(e) => handleChangeItem("cvx")(e.target.value ?? "")}
              value={itemValues.cvx}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.UnitCost ? settingsList.ItemLabels?.UnitCost :"Unit Cost ($)"}<sup>*</sup>
            </Form.Label>
            <div className="input-with-button">
              <Form.Control
                type="text"
                placeholder="Enter Cost"
                id="cptCode"
                name="cptCode"
                onChange={(e) =>
                  handleChangeItem("cptCode")(e.target.value ?? "")
                }
                value={itemValues.cptCode}
                isInvalid={itemTouched.cptCode && !!itemErrors.cptCode}
              />
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Open Calculator</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <button type="button" onClick={toggleCalcModal}>
                  Calculate
                </button>
              </OverlayTrigger>
            </div>
            {itemTouched.cptCode && itemErrors.cptCode && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.cptCode}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label={settingsList?.ItemLabels && settingsList?.ItemLabels?.Recall ? settingsList.ItemLabels?.Recall :"Recall"}
                type="checkbox"
                id="isRecalled"
                name="isRecalled"
                onChange={handleChangeItem}
                checked={itemValues.isRecalled}
              />
              <Form.Check
                inline
                label={settingsList?.ItemLabels && settingsList?.ItemLabels?.Kit ? settingsList.ItemLabels?.Kit :"Kit"}
                name="isKit"
                type="checkbox"
                onChange={handleChangeItem}
                checked={itemValues.isKit}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 full-width">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.Notes ? settingsList.ItemLabels?.Notes :"Notes"}
              </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="notes"
              name="notes"
              onChange={(e) => handleChangeItem("notes")(e.target.value ?? "")}
              value={itemValues.notes}
              isInvalid={itemTouched.notes && !!itemErrors.notes}
            />
            {itemTouched.notes && itemErrors.notes && (
              <Form.Control.Feedback type="invalid">
                {itemErrors.notes}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <h5 className="form-section-title full-width">Other Information</h5>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.SerialNumber ? settingsList.ItemLabels?.SerialNumber :"Serial Number"}
              </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Serial Number"
              id="serialNumber"
              name="serialNumber"
              onChange={(e) =>
                handleChangeItem("serialNumber")(e.target.value ?? "")
              }
              value={itemValues.serialNumber}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef1 ? settingsList.ItemLabels?.UserDef1 :"User Def 1"}
            </Form.Label>
            <Form.Control type="text" placeholder="Enter User Def 1" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef2 ? settingsList.ItemLabels?.UserDef2 :"User Def 2"}
            </Form.Label>
            <Form.Control type="text" placeholder="Enter User Def 2" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
               {settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef3 ? settingsList.ItemLabels?.UserDef3 :"User Def 3"}
            </Form.Label>
            <Form.Control type="text" placeholder="Enter User Def 3" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef4 ? settingsList.ItemLabels?.UserDef4 :"User Def 4"}
            </Form.Label>
            <Form.Control type="text" placeholder="Enter User Def 4" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef5 ? settingsList.ItemLabels?.UserDef5 :"User Def 5"}
            </Form.Label>
            <Form.Control type="text" placeholder="Enter User Def 5" />
          </Form.Group>
          <Form.Group className="mb-3 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={redirectToListing}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={false} onClick={itemSubmit as any}>
              {isEdit ? "Update" : "Add"}
            </Button>
          </Form.Group>
        </Form>
      </div>

      <LookUpModel
        show={show}
        handleClose={handleClose}
        setValues={setValues}
        renderItemLookUpPage={renderItemLookUpPage}
        setRenderItemLookUpPage={setRenderItemLookUpPage}
        setFormBarcodeValues={setFormBarcodeValues}
      />
      <OrderingProductsModel
        showModal={showModal}
        setSelectedOPValue={setSelectedOPValue}
        toggleModal={toggleModal}
        orderingProdOptions={orderingProdOptions}
        setOrderingProdOptions={setOrderingProdOptions}
        unitId={inventoryUnitOptions?.find(
          (option) => option.value === itemValues.unitId,
        )}
      />

      <UseUnitsModel
        showUseUnitModal={showUseUnitModal}
        toggleUseUnitModal={toggleUseUnitModal}
        unitsList={unitsList}
        invUnitVal={
          inventoryUnitOptions?.find(
            (option) => option.value === itemValues.unitId,
          )?.label
        }
        usUnitsList={usUnitsList}
        usUnits={usUnits}
      />
      {showCalcModal && (
        <CustomModal
          id="costcalcmodal"
          title="Cost Calculator"
          content={
            <div className="cost-calc-box">
              <Form onSubmit={costCalSubmit}>
                <div className="mb-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Total Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      min={1}
                      placeholder="Enter Total Quantity"
                      id="totalQuantity"
                      name="totalQuantity"
                      onChange={handleChangeCostCal}
                      value={costCalValues.totalQuantity}
                    />
                    {costCalTouched.totalQuantity &&
                      costCalErrors.totalQuantity && (
                        <Form.Control.Feedback type="invalid">
                          {costCalErrors.totalQuantity}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>
                <div className="mb-2">
                  <Form.Group className="mb-3">
                    <Form.Label>Total Cost</Form.Label>
                    <Form.Control
                      type="number"
                      min={1}
                      placeholder="Enter Price"
                      id="price"
                      name="price"
                      onChange={handleChangeCostCal}
                      value={costCalValues.price}
                    />
                    {costCalTouched.price && costCalErrors.price && (
                      <Form.Control.Feedback type="invalid">
                        {costCalErrors.price}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className="mb-2">
                  <label className="form-label">
                    Unit Cost : {handleCostChange(costCalValues)}
                  </label>
                </div>
                <div className="full-width text-end">
                  <Button
                    style={{ marginRight: "10px" }}
                    variant="outline-primary"
                    onClick={toggleCalcModal}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Use Cost</Button>
                </div>
              </Form>
            </div>
          }
          onClose={toggleCalcModal}
        />
      )}
    </div>
  );
};

export default AddItem;