import React, { useEffect, useState } from 'react';
import "./emrEmulator.scss";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { defaultEmrValues } from './emulatorConstant';

const defaultValues: { [key: string]: any } = defaultEmrValues;

const inputWithButtonFields = ["serverDate", "serverTime", "encounterDate", "emrOrderDate"];

const EmrEmulator = () => {
    const [values, setValues] = useState(defaultValues);
    const [rxInfoResponse, setRxInfoResponse] = useState('Response:');
    const baseUrl = useSelector((state: RootState) => state.config.configData?.appApiBaseUrl);

    useEffect(() => {
        const profileClientId = localStorage.getItem("profileClientId");
        setValues(prev => ({
            ...prev,
            wsUrl: `${baseUrl}/recordusage/getbarcodeinfo?tenantId=${profileClientId}`
        }));
    }, []);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setValues(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const getDate = () => {
        const currentTime = new Date();
        const month = currentTime.getMonth() + 1;
        const day = currentTime.getDate();
        const year = currentTime.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const getTime = () => {
        const currentTime = new Date();
        let hours = currentTime.getHours();
        let minutes: any = currentTime.getMinutes();
        let outString;

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        outString = hours + ":" + minutes + " ";
        if (hours > 11) {
            outString += "PM";
        } else {
            outString += "AM";
        }

        return outString;
    };

    const handleSetDateTime = () => {
        setValues(prev => ({
            ...prev,
            serverDate: getDate(),
            serverTime: getTime()
        }));
    };

    const handleFieldButtonClick = (fieldName: string) => {
        if (!fieldName) return;

        setValues(prev => ({
            ...prev,
            [fieldName]: fieldName.toLocaleLowerCase().includes('time') ? getTime() : getDate(),
        }))
    }

    const getBarcodeInfo = () => {
        const { wsUrl, ...rest } = values;
        try {
            fetch(wsUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rest)
            })
                .then(async response => {
                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(JSON.stringify(errorData));
                    }
                    return response.json();
                })
                .then(data => {
                    setRxInfoResponse(prev => prev + '<br><br>' + JSON.stringify(data, null, 4));
                })
                .catch(error => {
                    setRxInfoResponse(prev => prev + '<br><br>' + error.message);
                });
        } catch (err: any) {
            setRxInfoResponse(err.message);
        }
    };

    const clearRxInfoResponse = () => {
        setRxInfoResponse('Response:');
    };

    return (
        <main className="emr-emulator-main p-4">
            <header className='header-main mb-3'>
                <h3><strong>SRX- EMR Emulator</strong></h3>
                <div className="actions-wrapper">
                    <button className="btn btn-info" onClick={getBarcodeInfo}>Get Barcode Info</button>
                    <button className="btn btn-info ml-2" onClick={clearRxInfoResponse}>Clear</button>
                    <button className="btn btn-info ml-2" onClick={handleSetDateTime}>Set Date/Time</button>
                </div>
            </header>

            <div
                className="p-3 bg-white mb-4 border response-box"
                dangerouslySetInnerHTML={{ __html: rxInfoResponse }}
            ></div>

            <div className='row g-2 p-2 m-0 border bg-white emr-form-main'>
                {Object.keys(defaultValues).map(key => (
                    <div
                        key={key}
                        className={`${key === 'wsUrl' ? 'col-12' : 'col-4'} p-2 m-0`}
                    >
                        <label className="mb-1" htmlFor={`${key}_input`}>
                            {key}:
                        </label>
                        {inputWithButtonFields.includes(key) ? (
                            <div className="input-with-button">
                                <input
                                    id={`${key}_input`}
                                    name={key}
                                    type="text"
                                    className="form-control"
                                    value={values?.[key] ?? ''}
                                    onChange={handleInputChange}
                                />
                                <button
                                    className="ml-2"
                                    type="button"
                                    onClick={() => handleFieldButtonClick(key)}
                                >
                                    {key?.toLocaleLowerCase()?.includes('time') ? 'Now' : 'Today'}
                                </button>
                            </div>
                        ) : (
                            <input
                                id={`${key}_input`}
                                name={key}
                                type="text"
                                className="form-control"
                                value={values?.[key] ?? ''}
                                onChange={handleInputChange}
                            />
                        )}
                    </div>
                ))}
            </div>
        </main>
    );
};

export default EmrEmulator;